import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Stack from "@mui/material/Stack";
import ApiClient from "lib/ApiClient";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  FormControl,
  InputLabel,
  Select, MenuItem
} from "@mui/material";
import moment from "lib/moment_ja";
import Loading from "components/Loading";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

/**
 * @param {function} setTitle
 * @returns {JSX.Element}
 * @constructor
 */
export default function PurchaseShow({setTitle}) {
  const { userAccountId } = useParams();

  const [userAccount, setUserAccount] = useState(null);
  const [restrictions, setRestrictions] = useState([]);

  const [restrictionTargetAction, setRestrictionTargetAction] = useState("");
  const [restrictionReason, setRestrictionReason] = useState("");

  const [loading, setLoading] = useState(false);

  const [invoiceRegistrationNumber, setInvoiceRegistrationNumber] = useState("");

  useEffect(() => {
    setTitle("ユーザー管理");
    reloadUserAccount();
  }, []);

  const reloadUserAccount = () => {
    setLoading(true);
    ApiClient
      .get(`/secure/admin/user_accounts/${userAccountId}`)
      .then((response) => {
        switch(response.data.result) {
          case "SUCCESS":
            setUserAccount(response.data.userAccount);
            setRestrictions(response.data.restrictions);
            setInvoiceRegistrationNumber(response.data.sellerInformation?.invoiceRegistrationNumber || "");
            break;
          case "FAILURE":
            toast.error("エラーが発生しました");
            break;
        }
        setLoading(false);
      })
  }

  const restrictButtonClicked = () => {
    setLoading(true);

    ApiClient
      .post(`/secure/admin/user_restrictions`, {
        user_account_id: userAccountId,
        target_action: restrictionTargetAction,
        reason: restrictionReason
      })
      .then((response) => {
        switch(response.data.result) {
          case "SUCCESS":
            toast.success("利用制限を追加しました");
            setRestrictionTargetAction("");
            setRestrictionReason("");
            reloadUserAccount();
            break;
          case "FAILURE":
            toast.error(response.data.errorMessages.join("\n"));
            break;
        }
        setLoading(false);
      })
  }

  /**
   * @param {number} userRestrictionId
   */
  const removeRestrictionButtonClicked = (userRestrictionId) => {
    setLoading(true);

    ApiClient
      .put(`/secure/admin/user_restrictions/${userRestrictionId}/remove`)
      .then((response) => {
        switch(response.data.result) {
          case "SUCCESS":
            toast.success("利用制限を解除しました");
            reloadUserAccount();
            break;
          case "FAILURE":
            toast.error(response.data.errorMessages.join("\n"));
            break;
        }
        setLoading(false);
      })
  }

  const updateSellerInformation = () => {
    setLoading(true);
    ApiClient
      .put(`/secure/admin/seller_informations`, {
        user_account_id: userAccountId,
        invoice_registration_number: invoiceRegistrationNumber
      })
      .then((response) => {
        switch(response.data.result) {
          case "SUCCESS":
            toast.success("出品者情報を更新しました");
            reloadUserAccount();
            break;
          case "FAILURE":
            toast.error(response.data.errorMessages.join("\n"));
            break;
        }
        setLoading(false);
      })
  }

  return userAccount && (
    <div id="UserAccountIndex">
      <Loading loading={loading} />
      <ToastContainer autoClose={10000} />
      <Stack>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">ID</TableCell>
              <TableCell align="center">表示名</TableCell>
              <TableCell align="center">メールアドレス</TableCell>
              <TableCell align="center">登録日時</TableCell>
              <TableCell align="center">退会日時</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={userAccount.id}>
              <TableCell align="right">{userAccount.id}</TableCell>
              <TableCell align="center">{userAccount.displayName}</TableCell>
              <TableCell align="center">{userAccount.email}</TableCell>
              <TableCell align="right">{moment(userAccount.registeredAt).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
              <TableCell align="right">{userAccount.resignedAt ? moment(userAccount.resignedAt).format("YYYY-MM-DD HH:mm:ss") : ""}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
      <Stack sx={{ marginTop: "2rem" }}>
        <Typography variant="subtitle1" component="h3" align="left">出品者情報</Typography>
        <Stack direction="row" spacing={2} sx={{ marginTop: "1rem" }}>
          <FormControl>
            <TextField
              id="invoice-registration-number"
              label="適格請求書発行事業者登録番号"
              sx={{ width: 300 }}
              variant="outlined"
              value={invoiceRegistrationNumber}
              onChange={(event) => setInvoiceRegistrationNumber(event.target.value)}
            />
          </FormControl>
          <Button variant={"contained"} onClick={() => updateSellerInformation()}>更新</Button>
        </Stack>
      </Stack>
      <Stack sx={{ marginTop: "2rem" }}>
        <Typography variant="subtitle1" component="h3" align="left">利用制限</Typography>
        <Stack direction="row" spacing={2} sx={{ marginTop: "1rem" }}>
          <FormControl>
            <InputLabel id="label-target_action">制限内容</InputLabel>
            <Select
              labelId="label-target_action"
              id="target_action"
              variant={"outlined"}
              sx={{ width: 200  }}
              label="制限内容"
              value={restrictionTargetAction}
              onChange={(event) => setRestrictionTargetAction(event.target.value)}
            >
              <MenuItem value="">選択してください</MenuItem>
              <MenuItem value="selling">新規出品・新規販売</MenuItem>
              <MenuItem value="withdrawal">出金申請</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <TextField
              id="outlined-basic"
              label="制限理由"
              sx={{ width: 300 }}
              variant="outlined"
              value={restrictionReason}
              onChange={(event) => {
                setRestrictionReason(event.target.value);
              }}
            />
          </FormControl>
          <Button variant={"contained"} onClick={() => restrictButtonClicked()}>送信</Button>
        </Stack>
        <Stack direction="row" spacing={2} sx={{ marginTop: "1rem" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">制限内容</TableCell>
                <TableCell align="left">制限理由</TableCell>
                <TableCell align="center">制限日時</TableCell>
                <TableCell align="center">解除日時</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {restrictions.map((restriction) => (
                <TableRow key={restriction.id}>
                  <TableCell align="center">{restriction.targetAction}</TableCell>
                  <TableCell align="center">{restriction.reason}</TableCell>
                  <TableCell align="center">{moment(restriction.createdAt).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                  <TableCell align="center">
                    {
                      restriction.removedAt ?
                        moment(restriction.removedAt).format("YYYY-MM-DD HH:mm:ss") : (
                          <Button color={"error"} variant={"contained"} onClick={
                            () => removeRestrictionButtonClicked(restriction.id)
                          }>解除</Button>
                        )
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Stack>
      </Stack>
    </div>
  );
}