import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ApiClient from "lib/ApiClient";
import {Table, TableHead, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import moment from "lib/moment_ja";
import Loading from "components/Loading";

/**
 * @param {function} setTitle
 * @returns {JSX.Element}
 * @constructor
 */
export default function PurchaseIndex({setTitle}) {
  const [searchQuery, setSearchQuery] = useState("");

  const [searchResults, setSearchResults] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTitle("ユーザー管理");
  }, []);

  const searchButtonClicked = () => {
    setLoading(true);

    ApiClient
      .get(`/secure/admin/user_accounts/search?query=${searchQuery}`)
      .then((response) => {
        switch(response.data.result) {
          case "SUCCESS":
            setSearchResults(response.data.userAccounts);
            break;
          case "FAILURE":
            toast.error("エラーが発生しました");
            break;
        }
        setLoading(false);
      })
  }

  return (
    <div id="UserAccountIndex">
      <Loading loading={loading} />
      <ToastContainer autoClose={10000} />
      <Stack spacing={2} sx={{ marginTop: "2rem" }}>
        <Stack direction={"row"} spacing={2}>
          <TextField id="outlined-basic" label="ユーザーID/メールアドレス/表示名" variant="outlined" value={searchQuery} onChange={(event) => {
            setSearchQuery(event.target.value);
          }} />
          <Button variant={"outlined"} onClick={() => searchButtonClicked()}>検索</Button>
        </Stack>
      </Stack>
      <Stack>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center">ID</TableCell>
              <TableCell align="center">表示名</TableCell>
              <TableCell align="center">メールアドレス</TableCell>
              <TableCell align="center">登録日時</TableCell>
              <TableCell align="center">退会日時</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchResults.map((userAccount) => (
              <TableRow key={userAccount.id}>
                <TableCell align="center"><Link to={`/secure/user_account/${userAccount.id}`}>詳細</Link></TableCell>
                <TableCell align="right">{userAccount.id}</TableCell>
                <TableCell align="center">{userAccount.displayName}</TableCell>
                <TableCell align="center">{userAccount.email}</TableCell>
                <TableCell align="right">{moment(userAccount.registeredAt).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                <TableCell align="right">{userAccount.resignedAt ? moment(userAccount.resignedAt).format("YYYY-MM-DD HH:mm:ss") : ""}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Stack>
    </div>
  );
}