import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import ApiClient from "lib/ApiClient";
import Loading from "components/Loading";
import moment from "lib/moment_ja";
import config from "../../Config";

export default function DeliveryComplaintShow({setTitle}) {
  const { deliveryComplaintId } = useParams();

  const [complaint, setComplaint] = useState(null);

  const [loading, setLoading] = useState(false);

  const [openAcceptanceDialog, setOpenAcceptanceDialog] = useState(false);
  const [openRejectionDialog, setOpenRejectionDialog] = useState(false);

  useEffect(() => {
    setTitle("提供内容に関する問題報告");

    setLoading(true);

    ApiClient
      .get(`/secure/admin/delivery_complaints/${deliveryComplaintId}`)
      .then((response) => {
        switch(response.data.result) {
          case 'SUCCESS':
            setComplaint(response.data.deliveryComplaint);
            break;
          case 'FAILURE':
            break;
          default:
            break;
        }
        setLoading(false);
      });
  }, [deliveryComplaintId]);

  const cancelAcceptanceButtonClicked = () => {
    setOpenAcceptanceDialog(false);
  }

  const acceptanceButtonClicked = () => {
    setLoading(true);

    ApiClient
      .put(`/secure/admin/delivery_complaints/${deliveryComplaintId}/accept`)
      .then((response) => {
        switch(response.data.result) {
          case 'SUCCESS':
            setComplaint(response.data.deliveryComplaint);
            setOpenAcceptanceDialog(false);
            break;
          case 'FAILURE':
            break;
          default:
            break;
        }
        setLoading(false);
      });
  }

  const cancelRejectionButtonClicked = () => {
    setOpenRejectionDialog(false);
  }

  const rejectionButtonClicked = () => {
    setLoading(true);

    ApiClient
      .put(`/secure/admin/delivery_complaints/${deliveryComplaintId}/reject`)
      .then((response) => {
        switch(response.data.result) {
          case 'SUCCESS':
            setComplaint(response.data.deliveryComplaint);
            setOpenRejectionDialog(false);
            break;
          case 'FAILURE':
            break;
          default:
            break;
        }
        setLoading(false);
      });
  }

  return (
    <div id="DeliveryComplaintShow">
      <Loading loading={loading} />
      {
        complaint && (
          <Container fixed>
            <Stack direction="column" spacing={2}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>{complaint.id}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>ステータス</TableCell>
                      <TableCell>{complaint.state}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>サービス名</TableCell>
                      <TableCell>{complaint.serviceMenu.title}（ID：{complaint.serviceMenu.id}）</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>送信内容</TableCell>
                      <TableCell sx={{whiteSpace: 'pre-wrap'}}>{complaint.content}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>送信日時</TableCell>
                      <TableCell>{moment(complaint.submittedAt).format("YYYY年M月D日 H:mm")}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>購入ステータス</TableCell>
                      <TableCell>{complaint.purchase.state}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>購入者</TableCell>
                      <TableCell>
                        {complaint.buyer.displayName}（ID：{complaint.buyer.id}）<br/>
                        <a
                          href={`${config.frontendBaseUrl}/secure/talk_room/new/${complaint.buyer.id}`}
                          target="_blank"
                          rel="noreferrer"
                        >ログイン中アカウントとのトークルーム</a>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>出品者</TableCell>
                      <TableCell>
                        {complaint.seller.displayName}（ID：{complaint.seller.id}）<br/>
                        <a
                          href={`${config.frontendBaseUrl}/secure/talk_room/new/${complaint.seller.id}`}
                          target="_blank"
                          rel="noreferrer"
                        >ログイン中アカウントとのトークルーム</a>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>出品者ー購入者のトークルーム</TableCell>
                      <TableCell>
                        <a href={`${config.frontendBaseUrl}/secure/talk_room/${complaint.talkRoomId}`} target="_blank" rel="noreferrer">
                          {`${config.frontendBaseUrl}/secure/talk_room/${complaint.talkRoomId}`}
                        </a><br />
                        ※出品者または購入者になりかわる必要があります
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              {
                complaint.state === 'submitted' && complaint.purchase.state != 'canceled' ? (
                  <Stack direction="row" spacing={1}>
                    <Button variant="outlined" color="success" onClick={() => {
                      setOpenAcceptanceDialog(true);
                    }}>異議を認める（購入キャンセル）</Button>
                    <Button variant="outlined" color="success" onClick={() => {
                      setOpenRejectionDialog(true);
                    }}>異議を却下する（売上金確定）</Button>
                  </Stack>
                ) : null
              }
            </Stack>
          </Container>
        )
      }

      <Dialog open={openAcceptanceDialog}>
        <DialogTitle>異議を認める（購入キャンセル）</DialogTitle>
        <DialogContent>
          <DialogContentText>異議申し立てを認め、購入をキャンセルします。</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => cancelAcceptanceButtonClicked()}>キャンセル</Button>
          <Button onClick={() => acceptanceButtonClicked()}>実行</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openRejectionDialog}>
        <DialogTitle>異議を却下する（売上金確定）</DialogTitle>
        <DialogContent>
          <DialogContentText>異議申し立てを却下し、売上金を確定します。</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => cancelRejectionButtonClicked()}>キャンセル</Button>
          <Button onClick={() => rejectionButtonClicked()}>実行</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}