import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ApiClient from "../../lib/ApiClient";
import {Checkbox, FormControlLabel, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import moment from "lib/moment_ja";
import config from "../../Config";
import Loading from "../../components/Loading";

/**
 * @param {function} setTitle
 * @returns {JSX.Element}
 * @constructor
 */
export default function PurchaseIndex({setTitle}) {
  const [purchaseId, setPurchaseId] = useState(null);
  const [acceptNegativeAccountPayableFlag, setAcceptNegativeAccountPayableFlag] = useState(false);
  const [chargebackFlag, setChargebackFlag] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [purchase, setPurchase] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTitle("返金");
  }, []);

  const loadPurchase = () => {
    setLoading(true);

    ApiClient
      .get(`/secure/admin/purchases/${purchaseId}`)
      .then((response) => {
        switch(response.data.result) {
          case 'SUCCESS':
            setPurchase(response.data.purchase);
            break;
          case 'FAILURE':
            toast(response.data.errorMessages.join(", "), {type: 'error'});
            break;
          default:
            break;
        }
        setLoading(false);
      });
  }

  const searchButtonClicked = () => {
    loadPurchase();
  }

  const refundButtonClicked = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = (confirmed) => {
    setDialogOpen(false);
    if (confirmed) {
      performRefund();
    }
  };

  const performRefund = () => {
    setLoading(true);

    ApiClient
      .put(`/secure/admin/purchases/${purchaseId}/refund`, {
        accept_negative_account_payable: acceptNegativeAccountPayableFlag,
        chargeback_flag: chargebackFlag
      })
      .then((response) => {
        switch (response.data.result) {
          case 'SUCCESS':
            loadPurchase();
            break;
          case 'FAILURE':
            toast(response.data.errorMessages.join(", "), {type: 'error'});
            break;
          default:
            break;
        }
        setLoading(false);
      });
  };

  return (
    <div id="ImpersonationIndex">
      <Loading loading={loading} />
      <ToastContainer autoClose={10000} />
      <Stack spacing={2} sx={{ marginTop: "2rem" }}>
        <Stack direction={"row"} spacing={2}>
          <TextField id="outlined-basic" label="購入ID" variant="outlined" value={purchaseId} onChange={(event) => {
            setPurchaseId(event.target.value);
          }} />
          <Button variant={"outlined"} onClick={() => searchButtonClicked()}>検索</Button>
        </Stack>
        {
          purchase && (
            <>
              <Stack>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell>購入ID</TableCell>
                        <TableCell>{purchase.id}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>購入ステータス</TableCell>
                        <TableCell>{purchase.state}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>出品者</TableCell>
                        <TableCell>
                          {purchase.seller.displayName}（ID：{purchase.seller.id}）<br/>
                          <a
                            href={`${config.frontendBaseUrl}/secure/talk_room/new/${purchase.seller.id}`}
                            target="_blank"
                            rel="noreferrer"
                          >ログイン中アカウントとのトークルーム</a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>購入者</TableCell>
                        <TableCell>
                          {purchase.buyer.displayName}（ID：{purchase.buyer.id}）<br/>
                          <a
                            href={`${config.frontendBaseUrl}/secure/talk_room/new/${purchase.buyer.id}`}
                            target="_blank"
                            rel="noreferrer"
                          >ログイン中アカウントとのトークルーム</a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>商品名</TableCell>
                        <TableCell>{purchase.serviceMenu.title}（ID: {purchase.serviceMenu.id} /
                          提供方法：{purchase.serviceMenu.providingMethod}）</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>金額</TableCell>
                        <TableCell>{purchase.amount.toLocaleString()}円</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>購入日時</TableCell>
                        <TableCell>{moment(purchase.purchasedAt).format("YYYY年M月D日 H:mm")}</TableCell>
                      </TableRow>
                      {
                        purchase.sellerSales && (
                          <TableRow>
                            <TableCell>売上金確定日時</TableCell>
                            <TableCell>{moment(purchase.sellerSales.verifiedAt).format("YYYY年M月D日 H:mm")}</TableCell>
                          </TableRow>
                        )
                      }
                      {
                        purchase.withdrawalRequests && (
                          <>
                            {
                              purchase.withdrawalRequests.map((wr, index) => (
                                <>
                                  <TableRow>
                                    <TableCell>出金申請{index + 1}：ID</TableCell>
                                    <TableCell>{wr.id}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>出金申請{index + 1}：申請日</TableCell>
                                    <TableCell>{moment(wr.requestedOn).format('YYYY年M月D日')}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>出金申請{index + 1}：出金予定日</TableCell>
                                    <TableCell>{moment(wr.scheduledWithdrawalDate).format('YYYY年M月D日')}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>出金申請{index + 1}：ステータス</TableCell>
                                    <TableCell>{wr.state}</TableCell>
                                  </TableRow>
                                </>
                              ))
                            }
                            <TableRow>
                              <TableCell>出金申請可能額</TableCell>
                              <TableCell>{purchase.seller.withdrawalAmount.toLocaleString()}円</TableCell>
                            </TableRow>
                          </>
                        )
                      }
                      {
                        purchase.affiliate && (
                          <>
                            <TableRow>
                              <TableCell>アフィリエイト：ステータス</TableCell>
                              <TableCell>{purchase.affiliate.orderState}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>アフィリエイト：アフィリエイター</TableCell>
                              <TableCell>
                                {purchase.affiliate.affiliater.displayName}（ID：{purchase.affiliate.affiliater.id}）<br/>
                                <a
                                  href={`${config.frontendBaseUrl}/secure/talk_room/new/${purchase.affiliate.affiliater.id}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >ログイン中アカウントとのトークルーム</a>
                              </TableCell>
                            </TableRow>
                            {
                              purchase.affiliate.approvedAt && (
                                <>
                                  <TableRow>
                                    <TableCell>アフィリエイト：報酬額</TableCell>
                                    <TableCell>{purchase.affiliate.rewardAmount.toLocaleString()}円</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>アフィリエイト：報酬確定日時</TableCell>
                                    <TableCell>{moment(purchase.affiliate.approvedAt).format("YYYY年M月D日 H:mm")}</TableCell>
                                  </TableRow>
                                </>
                              )
                            }
                            {
                              purchase.affiliate.withdrawalRequests && (
                                <>
                                  {
                                    purchase.affiliate.withdrawalRequests.map((wr, index) => (
                                      <>
                                        <TableRow>
                                          <TableCell>アフィリエイト：報酬出金申請{index + 1}：ID</TableCell>
                                          <TableCell>{wr.id}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>アフィリエイト：報酬出金申請{index + 1}：申請日</TableCell>
                                          <TableCell>{moment(wr.requestedOn).format('YYYY年M月D日')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>アフィリエイト：報酬出金申請{index + 1}：出金予定日</TableCell>
                                          <TableCell>{moment(wr.scheduledWithdrawalDate).format('YYYY年M月D日')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>アフィリエイト：報酬出金申請{index + 1}：ステータス</TableCell>
                                          <TableCell>{wr.state}</TableCell>
                                        </TableRow>
                                      </>
                                    ))
                                  }
                                  <TableRow>
                                    <TableCell>アフィリエイト：出金申請可能額</TableCell>
                                    <TableCell>{purchase.affiliate.affiliater.withdrawalAmount.toLocaleString()}円</TableCell>
                                  </TableRow>
                                </>
                              )
                            }
                          </>
                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
              {
                purchase.refundableFlag && (
                  <Stack direction="column" spacing={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={chargebackFlag}
                          onChange={
                            (event) => {
                              setChargebackFlag(event.target.checked);
                              setAcceptNegativeAccountPayableFlag(event.target.checked);
                            }
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label="チャージバック"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={acceptNegativeAccountPayableFlag}
                          onChange={(event) => setAcceptNegativeAccountPayableFlag(event.target.checked)}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label="マイナスの未払金を許容する"
                    />
                    <Button variant="contained" color="error" onClick={() => refundButtonClicked()}>返金する</Button>
                  </Stack>
                )
              }
            </>
          )
        }
      </Stack>
      <Dialog
        open={dialogOpen}
        onClose={() => handleDialogClose(false)}
      >
        <DialogTitle>確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            本当に返金しますか？<br />チャージバックの場合は「チャージバック」をチェックしないと2重に返金することになってしまうため、特に注意してください。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            キャンセル
          </Button>
          <Button onClick={() => handleDialogClose(true)} color="error">
            返金する
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}