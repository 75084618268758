import axios from 'axios';
import config from 'Config';

const ApiClient = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
  baseURL: config.apiBaseUrl,
  withCredentials: true,
  withXSRFToken: true,
  xsrfCookieName: 'CSRF-TOKEN',
  xsrfHeaderName: 'X-CSRF-Token'
});

ApiClient.interceptors.response.use((response) => {
  return response;
}, (error) => {
  switch (error.response.status) {
    case 401:
      window.location.href = '/';
      break;
    default:
      break;
  }
  return Promise.reject(error);
});

export default ApiClient;