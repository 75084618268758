import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import ApiClient from "lib/ApiClient";
import {toast, ToastContainer} from "react-toastify";
import Loading from "components/Loading";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import moment from "lib/moment_ja";

export default function ImpersonationIndex({setTitle}) {
  const location = useLocation();
  const queryParams = (new URLSearchParams(location.search));
  const defaultSearchQuery = queryParams.get('user_account_id');

  const [searchQuery, setSearchQuery] = useState(defaultSearchQuery);
  const [impersonating, setImpersonating] = useState(false);
  const [userAccountId, setUserAccountId] = useState(null);
  const [resignedFlag, setResignedFlag] = useState(false);

  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTitle("なり代わりログイン");
    
    ApiClient
      .get(`/secure/admin/impersonation`)
      .then((response) => {
        switch(response.data.result) {
          case 'SUCCESS':
            setImpersonating(response.data.impersonating || false);
            setUserAccountId(response.data.userAccountId);
            break;
          case 'FAILURE':
            break;
          default:
            break;
        }
      });
  }, []);

  const loginButtonClicked = (userAccountId) => {
    ApiClient
      .post(`/secure/admin/impersonation`, {
        user_account_id: userAccountId
      })
      .then((response) => {
        switch(response.data.result) {
          case 'SUCCESS':
            setImpersonating(response.data.impersonating || false);
            setUserAccountId(response.data.userAccountId);
            setResignedFlag(response.data.resignedFlag);
            break;
          case 'FAILURE':
            break;
          default:
            break;
        }
      });
  }

  const logoutButtonClicked = () => {
    ApiClient
      .delete(`/secure/admin/impersonation`)
      .then((response) => {
        switch(response.data.result) {
          case 'SUCCESS':
            setImpersonating(false);
            setUserAccountId(null);
            break;
          case 'FAILURE':
            break;
          default:
            break;
        }
      });
  }

  const searchButtonClicked = () => {
    setLoading(true);

    ApiClient
      .get(`/secure/admin/user_accounts/search?query=${searchQuery}`)
      .then((response) => {
        switch(response.data.result) {
          case "SUCCESS":
            setSearchResults(response.data.userAccounts);
            break;
          case "FAILURE":
            toast.error("エラーが発生しました");
            setSearchResults([]);
            break;
        }
        setLoading(false);
      })
  }

  return (
    <div id="ImpersonationIndex">
      <Loading loading={loading} />
      <ToastContainer autoClose={10000} />
      {
        impersonating && (
          <Stack spacing={2} sx={{ mt: 4 }}>
            <Alert severity="success">
              なり代わり中です（ユーザーID：{userAccountId}{resignedFlag && (<> ※退会済み</>)}）
            </Alert>
            <Button variant={"outlined"} color={"error"} onClick={() => logoutButtonClicked()}>解除する</Button>
          </Stack>
        )
      }
      <Stack direction={"row"} spacing={2} sx={{ mt: 4 }}>
        <TextField id="outlined-basic" label="ID/メールアドレス/表示名" variant="outlined" value={searchQuery} onChange={(event) => {
          setSearchQuery(event.target.value);
        }} />
        <Button variant={"contained"} onClick={() => searchButtonClicked()}>検索</Button>
      </Stack>
      {
        searchResults.length > 0 && (
          <Stack>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">ID</TableCell>
                  <TableCell align="center">表示名</TableCell>
                  <TableCell align="center">メールアドレス</TableCell>
                  <TableCell align="center">登録日時</TableCell>
                  <TableCell align="center">退会日時</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchResults.map((userAccount) => (
                  <TableRow key={userAccount.id}>
                    <TableCell align="right">{userAccount.id}</TableCell>
                    <TableCell align="center">{userAccount.displayName}</TableCell>
                    <TableCell align="center">{userAccount.email}</TableCell>
                    <TableCell align="right">{moment(userAccount.registeredAt).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                    <TableCell align="right">{userAccount.resignedAt ? moment(userAccount.resignedAt).format("YYYY-MM-DD HH:mm:ss") : ""}</TableCell>
                    <TableCell>
                      <Button variant={"contained"} onClick={() => {
                        loginButtonClicked(userAccount.id);
                      }}>成り代わる</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Stack>
        )
      }
    </div>
  );
}